<template>
    <div>
        <posts>
            <!--
 Se cargan dinamicamente desde los posts
 <default-button
                :title="$t('home.myPurposes')"
                :action="'/app/pages/myPurposes'"
                :image="require('@/assets/images/gallery/sq-5.jpg')"
            />

            <default-button
                :title="$t('home.purposes')"
                :action="'/app/pages/purposes'"
                :image="require('@/assets/images/gallery/sq-5.jpg')"
            />-->
        </posts>
    </div>
</template>

<script>

import posts from '../../../components/home/posts'

// import defaultButton from '@/components/home/defaultButton'
export default {
    name: "Home",
    components: { posts/*, defaultButton */ }

};
</script>
